import { httpLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { AppRouter } from "src/backend/trpc/trpcAppRouter";
import getApiRequestHeaders from "src/frontend/utils/getApiRequestHeaders";
import getBackendTrpcUrl from "src/frontend/utils/getBackendTrpcUrl";
import SuperJSON from "superjson";

const TrpcClient = createTRPCNext<AppRouter>({
  config(_opts) {
    const trpcUrl = getBackendTrpcUrl();
    const url = `${trpcUrl}/api/trpc`;
    return {
      links: [
        httpLink({
          async headers() {
            return getApiRequestHeaders();
          },
          url,
        }),
      ],
      transformer: SuperJSON,
    };
  },
  ssr: false,
});

export default TrpcClient;
