import { HTTPHeaders } from "@trpc/client";
import { CURRENT_TAB_SESSION_ID } from "src/frontend/components/misc/BrandIdSyncComponent";
import useAuthStore from "src/frontend/stores/useAuthStore";
import getBearerAuthHeader from "src/frontend/utils/getBearerAuthHeader";
import CustomRequestHeaders from "src/shared/constants/CustomRequestHeaders";

function getBrandIdForRequest(): string {
  return localStorage.getItem(CURRENT_TAB_SESSION_ID) ?? "";
}

export default async function getApiRequestHeaders(): Promise<HTTPHeaders> {
  const brandId = getBrandIdForRequest();
  const token = await useAuthStore.getState().user?.getIdToken();
  const AuthorizationHeader = getBearerAuthHeader(token!);
  const headers: HTTPHeaders = {
    Accept: "application/json",
    Authorization: AuthorizationHeader,
    [CustomRequestHeaders.BrandId]: brandId,
  };
  return headers;
}
