import { User } from "firebase/auth";
import LocalStorageKey from "src/frontend/constants/LocalStorageKey";
import useBrandStore from "src/frontend/stores/useBrandStore";
import FirebaseAuth from "src/frontend/utils/firebase/FirebaseAuth";
import { Maybe } from "src/shared/types/maybe/Maybe";
import noop from "src/shared/utils/noop";
import { create } from "zustand";

// undefined means we don't know yet if the user is logged in or not
// null means the user is not logged in
// User means the user is logged in
type State = {
  user: Maybe<User>;
};

type Actions = {
  logout: () => void;
};

const useAuthStore = create<Actions & State>((set) => ({
  logout: (): void => {
    set({ user: null });
    FirebaseAuth.signOut().catch(noop);
    useBrandStore.setState({ brand_id: "", brand_name: "" });
    localStorage.removeItem(LocalStorageKey.UserSignUp);
  },
  user: undefined,
}));

if (typeof window !== "undefined") {
  FirebaseAuth.onAuthStateChanged((user) => {
    if (user) {
      user
        .getIdToken()
        .then(() => {
          useAuthStore.setState({ user });
        })
        .catch((e) => {
          console.error(e);
          useAuthStore.getState().logout();
        });
    } else {
      /**
       * There is a weird behavior with Firebase auth where sometimes onAuthStateChanged
       * will be called with an undefined user, but actually the user is still
       * authenticated. This will cause the current user to be logged out, and then
       * momentarily logged in again. I don't know why that happens. However,
       * during app initialization when the user is logged out, this does need to
       * explicitly call logout to set the user to null. Therefore I'm checking
       * if the signup complete key is present here as a proxy for if the user
       * is still logged in. If not, proceed with logout.
       */
      const signupKey = localStorage.getItem(LocalStorageKey.UserSignUp);
      if (signupKey !== "true") {
        useAuthStore.getState().logout();
      }
    }
  });
}

export default useAuthStore;
