import React from "react";
import limitArrayLength from "src/shared/utils/arrays/limitArrayLength";
import { create } from "zustand";

type MousePositionStoreState = {
  xHistory: number[];
};

const defaultState: MousePositionStoreState = {
  xHistory: [],
};

export const useMousePositionHistoryStore = create<MousePositionStoreState>(
  () => defaultState,
);

type MousePositionHistoryProviderProps = {
  children: React.ReactNode;
};

export default function MousePositionHistoryProvider(
  props: MousePositionHistoryProviderProps,
) {
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    useMousePositionHistoryStore.setState(({ xHistory }) => ({
      xHistory: limitArrayLength(xHistory.concat(e.clientX), 100),
    }));
  };

  return <div onMouseMove={handleMouseMove}>{props.children}</div>;
}
