import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import type { AppProps } from "next/app";
import Head from "next/head";
import { PostHogProvider } from "posthog-js/react";
import React, { useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import UnderMaintenanceContainer from "src/frontend/components/UnderMaintenanceContainer";
import ErrorBoundary from "src/frontend/components/error/ErrorBoundary";
import MousePositionHistoryProvider from "src/frontend/components/providers/MousePositionHistoryProvider";
import { ThemeProvider } from "src/frontend/components/providers/ThemeProvider";
import "src/frontend/styles/globals.css";
import "src/frontend/styles/rbc.scss";
import invariant from "tiny-invariant";

function DocumentHead() {
  return (
    <Head>
      <meta content="#ff4f48" name="msapplication-TileColor" />
      <meta content="/ms-icon-144x144.png" name="msapplication-TileImage" />
      <meta content="#ff4f48" name="theme-color" />
      <meta content="Luca" name="author" />
      <meta content="noindex, nofollow" name="robots" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta
        content="Luca uses Machine Learning-based Customer Price Elasticities to target precise margin and revenue outcomes for your business."
        name="description"
      />
      <meta
        content="pricing software, smart pricing, price optimization, dynamic pricing, retail pricing, automated pricing, quoting software, price intelligence, commerce suite"
        name="keywords"
      />
      <meta content="/seo-image.png" property="og:image" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content="/seo-image.png" name="twitter:image" />
      <meta content="1280" property="og:image:width" />
      <meta content="800" property="og:image:height" />
      <meta content="en_US" property="og:locale" />
      <meta content="Luca" property="og:site_name" />
    </Head>
  );
}

const ENABLE_REACT_QUERY_DEVTOOLS = false;

function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());
  return (
    <UnderMaintenanceContainer>
      <MousePositionHistoryProvider>
        <PostHogProvider>
          <QueryClientProvider client={queryClient}>
            {ENABLE_REACT_QUERY_DEVTOOLS && (
              <ReactQueryDevtools position="bottom-right" />
            )}
            <ErrorBoundary>
              <DocumentHead />
              <ThemeProvider
                attribute="class"
                defaultTheme="light"
                enableSystem
              >
                <Component {...pageProps} />
              </ThemeProvider>
            </ErrorBoundary>
          </QueryClientProvider>
        </PostHogProvider>
      </MousePositionHistoryProvider>
    </UnderMaintenanceContainer>
  );
}

const TrpcApp = TrpcClient.withTRPC(App);

const clientSideID = process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID;
invariant(clientSideID != null, "LaunchDarkly client ID must be provided.");
export default withLDProvider({
  clientSideID,
  options: {
    logger: {
      debug: console.debug,
      error: console.error,
      info: () => null,
      warn: console.warn,
    },
  },
})(TrpcApp);
