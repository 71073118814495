import { Frown } from "lucide-react";

type ErrorFallbackComponentProps = {
  isLocal?: boolean;
};

export default function ErrorFallbackComponent(
  props: ErrorFallbackComponentProps,
): JSX.Element {
  if (props.isLocal) {
    return (
      <div>
        <p>An error occurred.</p>
      </div>
    );
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-center bg-background">
      <div className="flex flex-row items-center gap-2">
        <Frown size={42} />
        <h1 className="text-3xl">Something bad happened...</h1>
      </div>
      <h3 className="mt-8 text-xl">
        We messaged the team and they will get to work fixing the problem!
      </h3>
    </div>
  );
}
