import React from "react";
import ErrorFallbackComponent from "src/frontend/components/error/ErrorFallbackComponent";
import logClientError from "src/frontend/utils/logClientError";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";

type ErrorBoundaryProps = {
  children: React.ReactNode;
  fallbackComponent?: React.ReactNode;
};
type ErrorBoundaryState = { hasError: boolean };

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: any): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any): void {
    logClientError(error, SentryErrorEvent.GlobalErrorBoundaryHit, {
      extra: { errorInfo },
    });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return this.props.fallbackComponent ?? <ErrorFallbackComponent />;
    }

    return this.props.children;
  }
}
