import { useEffect } from "react";
import useBrandId from "src/frontend/hooks/useBrandId";
import randomId from "src/shared/utils/random/randomId";

export const CURRENT_TAB_SESSION_ID = randomId();

export function updateCurrentSessionBrandId(brandId: string) {
  localStorage.setItem(CURRENT_TAB_SESSION_ID, brandId);
}

/**
 * We disable subscribing to state updates in useBrandStore so multiple tabs
 * can be opened to view different brands.
 *
 * As a result, we need to store the current tab's selected brand ID which
 * is then used by Trpc for adding the brandID to API requests by TrpcClient.
 *
 * This component handles syncing the currently selected brand ID with this
 * local state which is specific to the current browser tab.
 */
export default function BrandIdSyncComponent() {
  const brandId = useBrandId();

  useEffect(() => {
    const handleWindowClose = () => {
      localStorage.removeItem(CURRENT_TAB_SESSION_ID);
    };
    updateCurrentSessionBrandId(brandId);
    window.addEventListener("beforeunload", handleWindowClose);
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, [brandId]);

  return null;
}
