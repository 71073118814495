import LocalStorageKey from "src/frontend/constants/LocalStorageKey";
import { PaidFeatureType } from "src/shared/trpc/common/enum/PaidFeature";
import { GetUserBrandsResponseType } from "src/shared/trpc/queries/getUserBrandsQuerySchema";
import noop from "src/shared/utils/noop";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export function toBrandStore(brand: GetUserBrandsResponseType): BrandStore {
  return {
    ...brand,
    brand_name: brand.brand_name!,
  };
}

export type BrandStore = {
  brand_id: string;
  brand_name: string;
  company_brand_ids: string[];
  company_id: string;
  company_name: string;
  discounted_price_enabled: boolean;
  member_price_enabled: boolean;
  paid_features: PaidFeatureType[];
  price_plan_generation_in_progress: boolean;
  subscriber_price_enabled: boolean;
  user_feature_blocklist: PaidFeatureType[];
};

const defaultBrand: BrandStore = {
  brand_id: "",
  brand_name: "",
  company_brand_ids: [],
  company_id: "",
  company_name: "",
  discounted_price_enabled: false,
  member_price_enabled: false,
  paid_features: [],
  price_plan_generation_in_progress: false,
  subscriber_price_enabled: false,
  user_feature_blocklist: [],
};

const useBrandStore = create<BrandStore>()(
  persist(() => defaultBrand, {
    name: LocalStorageKey.BrandStore,
  }),
);

if (typeof useBrandStore.persist !== "undefined") {
  const unsubscribe = useBrandStore.persist.onFinishHydration(noop);
  unsubscribe();
}

export default useBrandStore;
