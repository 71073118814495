import { getAuth, browserLocalPersistence } from "firebase/auth";
import firebase from "firebase/compat/app";

const FirebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
};

const FirebaseApp = firebase.initializeApp(FirebaseConfig);

const FirebaseAuth = getAuth(FirebaseApp);

FirebaseAuth.setPersistence(browserLocalPersistence).catch((e) => {
  console.error("Error setting up Firebase, error:", e);
});

export default FirebaseAuth;
