import { TrafficCone } from "lucide-react";
import useFlags from "src/frontend/hooks/useFlags";

type UnderMaintenanceContainerProps = {
  children: JSX.Element;
};

export default function UnderMaintenanceContainer({
  children,
}: UnderMaintenanceContainerProps): JSX.Element {
  const { maintenanceUiEnabled } = useFlags();
  if (maintenanceUiEnabled) {
    return (
      <div className="flex h-screen items-center justify-center">
        <TrafficCone className="mr-2" size={88} />
        <div>
          <p>Luca is currently under maintenance.</p>
          <p className="text-xs">Please bear with us...</p>
        </div>
      </div>
    );
  }

  return children;
}
