import * as Sentry from "@sentry/nextjs";
import { ScopeContext } from "@sentry/types/types/scope";
import SentryErrorEvent, {
  getErrorFingerprint,
  getErrorSeverity,
} from "src/shared/utils/sentryErrorUtils";

type ClientErrorInfo = {
  brandId?: string;
  extra: ScopeContext["extra"];
  userGoogleId?: string;
};

export default function logClientError(
  err: Error,
  sentryErrorEvent: SentryErrorEvent,
  info?: ClientErrorInfo,
): void {
  Sentry.captureException(err, {
    extra: {
      ...info?.extra,
      brandId: info?.brandId,
      userGoogleId: info?.userGoogleId,
    },
    fingerprint: getErrorFingerprint(err, sentryErrorEvent),
    level: getErrorSeverity(sentryErrorEvent),
    tags: {
      event: sentryErrorEvent,
    },
  });
}
